.show-more-enter {
    opacity: 0.01;
}

.show-more-enter.show-more-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.show-more-leave {
    opacity: 1;
}

.show-more-leave.show-more-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.sticky-sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
}
